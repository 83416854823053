.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.auth-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(135, 133, 133, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.logo-container {
    width: 100%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.logo-image-authscreen {
    width: 67%;
}

.fields-common {
    width: 100%;
    font-size: medium;
}

.password-field {
    position: relative;
}

.toggle-password {
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.terms {
    margin: 20px 0;
    text-align: left;
    font-size: smaller;
}

.pointer {
    cursor: pointer;
}

.terms label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.terms span {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.error-message {
    color: rgb(167, 0, 0);
    font-size: 12px;
    margin-top: 5px;
}

.link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 20px;
}

.reset-password-container {
    margin-top: -10px;
    width: 100%;
    text-align: right;
}

.auth-form-link {
    color: #8A4B9A;
    cursor: pointer;
    font-size: smaller;
}

.margin-v-20px {
    margin-top: 20px;
    margin-bottom: 20px;
}

.text-field-error {
    border: 2px solid rgb(167, 0, 0) !important;
    ;
}

.text-field {
    display: flex;
    align-items: center;
    border: 1px solid #9b9797;
    border-radius: 5px;
    padding: 5px;
    max-width: 100%;
    margin-bottom: 15px;
}

.text-field input {
    border: none;
    outline: none;
    flex-grow: 1;
    padding: 5px;
    font-size: medium;
}

.text-field .label-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
    color: #8A4B9A;
    cursor: pointer;
}

.text-field .icon {
    display: flex;
    align-items: center;
    margin-left: 5px;
    color: #9b9797;
    cursor: pointer;
}

.button-submit {
    height: 45px;
    padding: 10px 25px 10px 25px;
    border-radius: 20px;
    border: none;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.01), 0 2px 2px 0 rgba(0, 0, 0, 0.19);
    background-color: #fdf5ff;
    font-size: medium;
    font-weight: 600;
    color: #8A4B9A;
    margin-top: 20px;
}

.button-submit:hover {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.01), 0 3px 3px 0 rgba(0, 0, 0, 0.19);
    background-color: #f7e9fb;
    font-size: medium;
    font-weight: 600;
    color: #8A4B9A;
    cursor: pointer;
}

.error {
    color: rgb(167, 0, 0) !important;
}

.tooltip-parent {
    position: relative;
}

.tooltip {
    visibility: hidden;
    width: 120px;
    background-color: #f0eeee;
    color: #000000;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    font-size: x-small;
    /* Position above the icon */
    bottom: 145%;
    right: calc(0% - 12px);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    /* At the bottom of the tooltip */
    right: 15px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #e9e9e9 transparent transparent transparent;
}

.tooltip-visible {
    visibility: visible !important;
    opacity: 1 !important;
}

.spinner-div {
    width: 100%;
    font-size: medium;
    font-weight: 600;
    margin-top: 20px;
}

.spinner {
    margin: auto;
}

.error-from-server {
    width: 100%;
    text-align: left;
    color: rgb(167, 0, 0);
    font-size: smaller;
    margin-top: 10px;
}

.auth-copyright-div {
    margin-top: 80px;
    font-size: x-small;
    color: #000000;
    border-top: #979595;
    border-width: 1px 0 0 0;
    border-style: outset;
    padding-top: 10px;
}