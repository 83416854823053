.logo-in-appbar-container {
    vertical-align: middle;
    margin: auto 15px auto 0;
    max-height: 25px;
}

.logo-in-appbar-img {
    max-height: 25px;
}

.h1BusinessDetails {
    color: green;
    font-size: x-large;
    font-weight: 400;
    width: 100%;
    text-align: right;
}

.h2BusinessDetails {
    color: black;
    font-size: xx-large;
    font-weight: 400;
}

.h3BusinessDetails {
    color: black;
    font-size: x-large;
    font-weight: 400;
}

.h4BusinessDetails {
    color: black;
    font-size: large;
    font-weight: 400;
}

.pHeaderBusinessDetails {
    text-decoration: none;
    color: black;
    cursor: default;
    font-weight: bold;
}

.pseudoHrefWoColor {
    text-decoration: underline !important;
    cursor: pointer !important;
}

.pseudoHrefWithColor {
    text-decoration: underline !important;
    cursor: pointer !important;
    color: #1976D2 !important;
}

.decorationNone {
    text-decoration: none !important;
}

.pHeaderBusinessDetailsTooltip {
    text-decoration: underline;
    color: #1976D2 !important;
    cursor: pointer;
    font-weight: bold;
}